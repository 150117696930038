<template>
    <div class="content-wrapper">
        <bo-page-title />
        <div class="content pt-0">
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No SEP</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-6" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md">
                            <div class="form-group mb-0">
                            <input v-model="inpNoSEP" class="form-control" 
                            placeholder="Ketik No SEP" />
                            </div>
                        </div>
                        <div class="col-md-auto">
                            <button @click="searchNoSEP" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetNoSEP">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwNoSEP">
                        <div class="col-lg-12">
                            <table class="table table-bordered table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>catatan</h4>
                                            <p>{{dataNoSEP.catatan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>diagnosa</h4>
                                            <p>{{dataNoSEP.diagnosa||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>cob</h4>
                                            <p>{{dataNoSEP.cob||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>dpjp</h4>
                                            <p>{{dataNoSEP.dpjp.kdDPJP||"-"}}</p>
                                            <p>{{dataNoSEP.dpjp.nmDPJP||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>eSEP</h4>
                                            <p>{{dataNoSEP.eSEP||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  

                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>jnsPelayanan</h4>
                                            <p>{{dataNoSEP.jnsPelayanan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>katarak</h4>
                                            <p>{{dataNoSEP.katarak||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  

                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>kdStatusKecelakaan</h4>
                                            <p>{{dataNoSEP.kdStatusKecelakaan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>kelasRawat</h4>
                                            <p>{{dataNoSEP.kelasRawat||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>klsRawat</h4>
                                                <p>klsRawatHak : {{dataNoSEP.klsRawat.klsRawatHak||"-"}}</p>
                                                <p>klsRawatNaik : {{dataNoSEP.klsRawat.klsRawatNaik||"-"}}</p>
                                                <p>pembiayaan : {{dataNoSEP.klsRawat.pembiayaan||"-"}}</p>
                                                <p>penanggungJawab : {{dataNoSEP.klsRawat.penanggungJawab||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>kontrol</h4>
                                                <p>kdDokter : {{dataNoSEP.kontrol.kdDokter||"-"}}</p>
                                                <p>nmDokter : {{dataNoSEP.kontrol.nmDokter||"-"}}</p>
                                                <p>noSurat : {{dataNoSEP.kontrol.noSurat||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Lokasi Kecelakaan</h4>
                                                <p>kdKab : {{dataNoSEP.lokasiKejadian.kdKab||"-"}}</p>
                                                <p>kdKec : {{dataNoSEP.lokasiKejadian.kdKec||"-"}}</p>
                                                <p>kdProp : {{dataNoSEP.lokasiKejadian.kdProp||"-"}}</p>
                                                <p>ketKejadian : {{dataNoSEP.lokasiKejadian.ketKejadian||"-"}}</p>
                                                <p>lokasi : {{dataNoSEP.lokasiKejadian.lokasi||"-"}}</p>
                                                <p>tglKejadian : {{dataNoSEP.lokasiKejadian.tglKejadian||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  

                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>peserta</h4>
                                                <p>asuransi : {{dataNoSEP.peserta.asuransi||"-"}}</p>
                                                <p>hakKelas : {{dataNoSEP.peserta.hakKelas||"-"}}</p>
                                                <p>jnsPeserta : {{dataNoSEP.peserta.jnsPeserta||"-"}}</p>
                                                <p>kelamin : {{dataNoSEP.peserta.kelamin||"-"}}</p>
                                                <p>nama : {{dataNoSEP.peserta.nama||"-"}}</p>
                                                <p>noKartu : {{dataNoSEP.peserta.noKartu||"-"}}</p>
                                                <p>noMr : {{dataNoSEP.peserta.noMr||"-"}}</p>
                                                <p>tglLahir : {{dataNoSEP.peserta.tglLahir||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>nmstatusKecelakaan</h4>
                                                <p>kdKab : {{dataNoSEP.nmstatusKecelakaan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  

                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>nmstatusKecelakaan</h4>
                                                <p>{{dataNoSEP.nmstatusKecelakaan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>noRujukan</h4>
                                                <p>{{dataNoSEP.noRujukan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>noSep</h4>
                                                <p>{{dataNoSEP.noSep||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>penjamin</h4>
                                                <p>{{dataNoSEP.penjamin||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>poli</h4>
                                                <p>{{dataNoSEP.poli||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>poliEksekutif</h4>
                                                <p>{{dataNoSEP.poliEksekutif||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>    

                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglSep</h4>
                                                <p>{{dataNoSEP.tglSep||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>
            
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No SEP Internal</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-6" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md">
                            <div class="form-group mb-0">
                            <input v-model="inpSEPList" class="form-control" 
                            placeholder="Ketik No SEP" />
                            </div>
                        </div>
                        <div class="col-md-auto">
                            <button @click="searchSEPList" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetSEPList">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwSEPList">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>tujuanrujuk</th>
                                    <th>nmtujuanrujuk</th>
                                    <th>nmpoliasal</th>
                                    <th>tglrujukinternal</th>
                                    <th>nosep</th>
                                    <th>nosepref</th>
                                    <th>ppkpelsep</th>
                                    <th>nokapst</th>
                                    <th>tglsep</th>
                                    <th>nosurat</th>
                                    <th>flaginternal</th>
                                    <th>kdpoliasal</th>
                                    <th>kdpolituj</th>
                                    <th>kdpenunjang</th>
                                    <th>nmpenunjang</th>
                                    <th>diagppk</th>
                                    <th>kddokter</th>
                                    <th>nmdokter</th>
                                    <th>flagprosedur</th>
                                    <th>opsikonsul</th>
                                    <th>flagsep</th>
                                    <th>fuser</th>
                                    <th>fdate</th>
                                    <th>nmdiag</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in dataSEPList.list" :key="v.kode">
                                        <td>{{k+1}}</td>
                                        <td>
                                            <span>{{v.tujuanrujuk||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.nmtujuanrujuk||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.nmpoliasal||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.tglrujukinternal||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.nosep||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.nosepref||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.ppkpelsep||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.nokapst||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.tglsep||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.nosurat||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.flaginternal||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.kdpoliasal||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.kdpolituj||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.kdpenunjang||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.nmpenunjang||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.diagppk||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.kddokter||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.nmdokter||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.flagprosedur||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.opsikonsul||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.flagsep||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.fuser||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.fdate||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.nmdiag||"-"}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek SEP Finger Print</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-8" id="formOptPenunjang">
                            <div class="row gx-1">
                            <div class="col-md">
                                <div class="form-group mb-0">
                                <input v-model="inpSEPFinger" class="form-control" 
                                placeholder="Ketik No Kartu" />
                                </div>
                            </div>
                            
                            <div class="col-md-4">
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent"
                                    placeholder="Pilih Tanggal" class="my-datepicker"
                                    calendar-class="my-datepicker_calendar" v-model="tglSEPFinger">
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i
                                        class="icon-calendar"></i></span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-auto">
                                <button @click="searchSEPFinger" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetSEPFinger">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" v-if="shwSEPFinger">
                        <div class="col-lg-12">
                            <table class="table table-bordered table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Kode</h4>
                                                <p>{{dataSEPFinger.kode||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Status</h4>
                                            <p>{{dataSEPFinger.status||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

             <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek List Finger Print</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-8" id="formOptPenunjang">
                            <div class="row gx-1">
                            
                            <div class="col-md-4">
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent"
                                    placeholder="Pilih Tanggal" class="my-datepicker"
                                    calendar-class="my-datepicker_calendar" v-model="inpSEPFingerList">
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i
                                        class="icon-calendar"></i></span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-auto">
                                <button @click="searchSEPFingerList" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetSEPFingerList">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" v-if="shwSEPFingerList">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>No Kartu</th>
                                    <th>No SEP</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in dataSEPFingerList.list" :key="v.kode">
                                        <td>{{k+1}}</td>
                                        <td>
                                            <span>{{v.noKartu||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.noSEP||"-"}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
    </div>    
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'

export default {
  extends: GlobalVue,
  data() {
    return {
        inpNoSEP:"",
        shwNoSEP: false,  
        dataNoSEP: {},

        
        inpSEPList:"",
        shwSEPList: false,  
        dataSEPList: {},

        
        inpSEPFinger:"",
        tglSEPFinger: null,  
        shwSEPFinger: false,  
        dataSEPFinger: {},

        
        inpSEPFingerList:"",
        shwSEPFingerList: false,  
        dataSEPFingerList: {},
    }
  },
  components: {
    Datepicker
  },
  methods: {
    searchNoSEP(){
        if(this.inpNoSEP){
            this.loadingOverlay = true
            let data = {
                type : "search-sep",
                search : this.inpNoSEP
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwNoSEP = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data SEP Tidak Ditemukan'
                    })
                }
                this.dataNoSEP = resp.response
                console.log(this.dataNoSEP)
                this.loadingOverlay = false
                this.shwNoSEP = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data SEP Tidak Ditemukan'
                })
            })
        }
    },
    resetNoSEP(){
        this.inpNoSEP = null
        this.shwNoSEP = false
        this.dataNoSEP = {}
    },

    searchSEPList(){
        if(this.inpSEPList){
            this.loadingOverlay = true
            let data = {
                type : "search-sep-internal",
                search : this.inpSEPList
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwSEPList = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data SEP Internal Tidak Ditemukan'
                    })
                }
                this.dataSEPList = resp.response
                this.loadingOverlay = false
                this.shwSEPList = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data SEP Internal Tidak Ditemukan'
                })
            })
        }
    },
    resetSEPList(){
        this.inpSEPList = null
        this.shwSEPList = false
        this.dataSEPList = {}
    },


    searchSEPFinger(){
        if(this.inpSEPFinger){
            this.loadingOverlay = true
            let data = {
                type : "search-finger-print-data",
                search : this.inpSEPFinger,
                tanggal : this.tglSEPFinger
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwSEPFinger = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Finger Print Tidak Ditemukan'
                    })
                }
                this.dataSEPFinger = resp.response
                this.loadingOverlay = false
                this.shwSEPFinger = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Finger Print Tidak Ditemukan'
                })
            })
        }
    },
    resetSEPFinger(){
        this.inpSEPFinger = null
        this.shwSEPFinger = false
        this.dataSEPFinger = {}
    },

    searchSEPFingerList(){
        if(this.inpSEPFingerList){
            this.loadingOverlay = true
            let data = {
                type : "search-finger-print-list",
                search : this.inpSEPFingerList
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwSEPFingerList = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Finger Print Tidak Ditemukan'
                    })
                }
                this.dataSEPFingerList = resp.response
                this.loadingOverlay = false
                this.shwSEPFingerList = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Finger Print Tidak Ditemukan'
                })
            })
        }
    },
    resetSEPFingerList(){
        this.inpSEPFingerList = null
        this.shwSEPFingerList = false
        this.dataSEPFingerList = {}
    }
  },
}
</script>